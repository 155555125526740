var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app',{staticClass:"bg-color",class:_vm.bgImages[_vm.$router.currentRoute.meta.name]},[_c('PageLoader',{attrs:{"target":_vm.hasLoaded}},[_c('div',{staticClass:"container-height"},[(_vm.navigation.primary)?_c('Navigation'):_vm._e(),(_vm.navigation.secondary)?_c('PublicNavigation'):_vm._e(),_c('div',{class:{
          'flex-grow-1 mt-n10 bg-color main-layout white py-5':
            !_vm.notAllowedRoutes.includes(_vm.$router.currentRoute.name),
        }},[_c('v-container',{staticClass:"pa-0 transparent-bg"},[_c('v-main',{staticClass:"mx-3",class:{
              'custom-main': _vm.notAllowedRoutes.includes(
                _vm.$router.currentRoute.name
              ),
            }},[_c('router-view'),_c('AlertComponent')],1)],1)],1),(_vm.allowedFooterRoutes.includes(_vm.$router.currentRoute.name))?_c('footer-component'):_vm._e()],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }